import './App.css'

function App() {
  return (
    <main className='font-primary relative'>
      <div
        className='bg-cover bg-right-top fixed portrait:hidden inset-0'
        style={{ backgroundImage: `url(landscape-small.jpg)` }}
      />

      <div
        className='bg-cover bg-center fixed landscape:hidden inset-0'
        style={{ backgroundImage: `url(portrait-small.jpg)` }}
      />

      <section className='flex flex-col items-center landscape:justify-center h-safe-screen leading-none portrait:p-[12vw] landscape:px-[3vw] relative text-white w-full landscape:w-1/2 landscape:laptop:w-2/3'>
        <div className='flex flex-col landscape:max-w-[30vw] landscape:tablet:max-w-[35vw] landscape:tablet-landscape:max-w-[40vw]'>
          <div className='font-bold mb-[2vh] portrait:text-[5vh] landscape:text-[6vh] tablet:text-[6vh]'>
            <p>Hi! I am Jille Borg</p>
          </div>

          <div className='font-light portrait:leading-[5vh] tablet:leading-[6vh] mb-[3vh] space-y-2 portrait:text-[3vh] landscape:text-[4vh] tablet:text-[4vh]'>
            <p>A Frontend Developer from Amsterdam with 10+ years experience</p>
          </div>

          <ul className='flex space-x-[1rem]'>
            <li>
              <a href='https://www.instagram.com/jilleborg/' rel='noreferrer' target='_blank'>
                <img
                  alt='instagram logo'
                  className='h-auto opacity-80 hover:opacity-100 transition-opacity w-[4vh]'
                  src='instagram.png'
                />
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/jilleborg/' rel='noreferrer' target='_blank'>
                <img
                  alt='linkedin logo'
                  className='h-auto opacity-80 hover:opacity-100 transition-opacity w-[4vh]'
                  src='linkedin.png'
                />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </main>
  )
}

export default App
